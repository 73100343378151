<template>
  <myDialog title="指数曲线" :isShow="isLineDialog" :hedeDialog="hedeReplyDialog">
    <div class="dialog_line_content">
      <LineEcharts :lineData="lineData" domID="lineDialog" :grid="grid" :isShowAxis="true"
                   :width="1" :showSymbol="true" />
    </div>

  </myDialog>
</template>
<script>
import myDialog from '@/components/myDialog/index.vue'
import LineEcharts from './line.vue'
export default {
  props: {
    isLineDialog: {
      type: Boolean,
      default: false
    },
    // 数据
    lineData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    myDialog,
    LineEcharts
  },
  data () {
    return {
      grid: {
        top: '6%',
        left: '10%',
        right: '5%',
        bottom: '13%'
      }
    }
  },
  methods: {
    hedeReplyDialog () {
      this.$emit('update:isLineDialog', false)
    }
  }
}
</script>
<style lang="less" scoped>
.dialog_line_content {
  width: 100%;
  height: 350px;
  background-color: #f5f7fb;
}
</style>
