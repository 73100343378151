<template>
  <div class="">
    <div class="Journal-list">
      <article-list :honDocuList="documentList" />
    </div>
    <!-- 加载更多按钮 -->
    <div class="bottom-btn">
      <template v-if="isseeMore">
        <span v-if="documentList.length>=10" @click="seeMore" class=" hand">查看更多>></span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    documentList: {
      type: Array,
      default: () => []
    },
    isseeMore: {
      type: Boolean,
      default: false
    }

  },
  components: {},
  data () {
    return {

    }
  },
  methods: {
    seeMore () {
      this.$emit('seeMore')
    }
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.Journal-list {
  min-height: 100vh;
}
.bottom-btn {
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  color: #4b639f;
  span:hover {
    font-weight: 700;
  }
}
/deep/.list:first-child {
  margin-top: 0;
}
</style>
