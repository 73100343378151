<template>
  <div ref="bgbox" v-if="isShow" class="dialog_container" @click="hideMyDialog"
       :style="`z-index:${zIndex}`">
    <div ref="my_dialog_box" @click.stop class="my_dialog_box loading"
         :style="`transform-origin: ${origin.x} ${origin.y}; width: ${width};`">
      <slot name="title">
        <div v-if="title" class="my_dialog_top_box">
          {{title}}
          <i @click="hideMyDialog" class="el-icon-close"></i>
        </div>
      </slot>
      <slot>
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    origin: {
      type: Object,
      default: () => ({ x: '50%', y: '50%' })
    },
    title: {
      type: String,
      default: ''
    },
    isDisableScroll: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '640px'
    },
    zIndex: {
      type: String,
      default: '2200'
    },
    hedeDialog: {
      type: Function,
      default: () => { }
    }
  },
  data () {
    return {
    }
  },
  methods: {
    setEditBox () {
      setTimeout(() => {
        if (!this.isDisableScroll) {
          const body = document.querySelector('body')
          body.style.overflow = 'hidden'
        }
        const box = this.$refs.my_dialog_box
        const bg = this.$refs.bgbox

        bg.style.opacity = '1'
        box.style.transform = 'scale(1)'
        box.style.opacity = '1'
      }, 100)
    },
    hideMyDialog () {
      if (!this.isDisableScroll) {
        const body = document.querySelector('body')
        body.style.overflow = 'visible'
      }
      const box = this.$refs.my_dialog_box
      const bg = this.$refs.bgbox
      bg.style.opacity = '0'
      box.style.transform = 'scale(0.1)'
      box.style.opacity = '0'
      setTimeout(() => {
        this.hedeDialog()
        this.$emit('update:isShow', false)
      }, 100)
    }
  },
  watch: {
    isShow (newval) {
      if (newval) {
        this.setEditBox()
      } else {
        if (!this.isDisableScroll) {
          const body = document.querySelector('body')
          body.style.overflow = 'visible'
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.dialog_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  color: #000;
  transition: opacity 0.4s;
  background-color: rgba(0, 0, 0, 0.45);
  .my_dialog_box {
    position: absolute;
    right: 0px;
    left: 0;
    top: 10vh;
    min-height: 382px;
    opacity: 0;
    z-index: 99;
    overflow: hidden;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 5px;
    transform: scale(0.1);
    // transform-origin: 150% 0%;
    transition: all 0.3s;
  }

  .my_dialog_top_box {
    position: relative;
    font-size: 14px;
    font-weight: 700;
    padding: 10px 20px;
    border-bottom: 1px solid #e8e8e8;
    .el-icon-close {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 16px;
      transform: rotate(0deg);
      cursor: pointer;
      transition: transform 0.3s;
      &:hover {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
