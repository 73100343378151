<template>
  <div class="table_container">
    <el-table :data="list" size="mini" :span-method="spanMethod" border style="width: 100%">
      <el-table-column v-for="(item,i) in tableMaps" :key="i" :prop="item.prop" :label="item.label"
                       :width="item.width || 'auto'" align="center">
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    tableMaps: {
      type: Array,
      default: () => []
    },
    isMerge: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data () {
    return {

    }
  },
  methods: {
    spanMethod ({ row, column, rowIndex, columnIndex }) {
      if (!this.isMerge) {
        return {
          rowspan: 1,
          colspan: 1
        }
      }

      if ([0, 1].includes(columnIndex)) {
        if (rowIndex === 0) {
          return {
            rowspan: this.list.length,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      } else {
        return {
          rowspan: 1,
          colspan: 1
        }
      }
    }
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
/deep/.el-table__row {
  background-color: #f5f7fb;
}
.el-table {
  color: #666;
  /deep/th.el-table__cell {
    background-color: #f5f7fb;
  }
}
</style>
