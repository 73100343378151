<template>
  <div :id="domID" class="line_content"></div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  props: {
    domID: {
      type: String,
      default: 'line_box'
    },
    // 数据
    lineData: {
      type: Object,
      default: () => ({})
    },
    // 网格
    grid: {
      type: Object,
      default: () => ({
        top: '2%',
        left: '2%',
        right: '2%',
        bottom: '5%'
      })
    },
    // 显示轴线
    isShowAxis: {
      type: Boolean,
      default: false
    },
    // 线宽
    width: {
      type: Number,
      default: 0.5
    },
    // 圈是否显示
    showSymbol: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    init () {
      const lineBox = document.querySelector('#' + this.domID)
      const myChart = echarts.init(lineBox)
      const options = {
        tooltip: {
          trigger: 'axis',
          formatter: '{b} 年 <br /> {c}',
          axisPointer: {
            lineStyle: {
              color: '#afcbe2'
            }
          }
        },
        // 统计图大小
        grid: this.grid,
        backgroundColor: '#f5f7fb',

        xAxis: {
          show: this.isShowAxis,
          type: 'category',
          data: Object.keys(this.lineData).sort((a, b) => a - b)
        },
        yAxis: {
          show: this.isShowAxis,
          type: 'value'
        },

        series: [
          {
            type: 'line',
            data: Object.values(this.lineData),
            symbolSize: this.width * 2,
            showSymbol: this.showSymbol,
            lineStyle: {
              width: this.width
            }

          }
        ]
      }
      myChart.setOption(options)
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="less" scoped>
.line_content {
  width: 100%;
  height: 100%;
}
</style>
