<template>
  <!-- 期刊详情页 -->
  <div class="JournalDetails">
    <div class="JournalDetails-box">
      <div class="JournalDetails-item">
        <!-- 期刊信息 -->
        <div class="Journal-info">
          <el-image :src="journalInfo.cover" class="flex_shrink0">
            <div slot="error" class="image-slot">
              <img class="el-image" src="@/assets/img/periodical/imgErr.png" alt="">
            </div>
          </el-image>
          <div class="info-text">
            <div class="info-title row_2">
              {{journalInfo.fullName}}
              <span class="fs14" v-if="journalInfo.fullCname">
                ( {{journalInfo.fullCname}} )
              </span>
            </div>
            <div class="tags_item row_1" v-if="journalInfo.tags">
              <span>领域: </span>
              <span>
                {{journalInfo.tags}}
              </span>
            </div>
            <div class="info-option">
              <span v-if="journalInfo.hitRate">投稿命中率: {{journalInfo.hitRate}}</span>
              <span v-if="journalInfo.issn" class="row_1">ISSN: {{journalInfo.issn}}</span>
              <span v-if="journalInfo.eissn" class="row_1">eISSN: {{journalInfo.eissn}}</span>
            </div>

            <div class="info-option">
              <span>文献数量: {{journalInfo.sum}}</span>
              <span>中文发文量: {{journalInfo.chinesePublishVol || 0}}</span>
              <span v-if="journalInfo.importFactor">IF: {{journalInfo.importFactor}}</span>
            </div>
          </div>
          <div class="view_type">
            <span class="view_change_btn flc hand" @click="viewChange">
              {{showJournalInfo?'年份期刊': '期刊介绍'}}
              <i class="el-icon-sort sort_icon"></i>
            </span>
          </div>
          <!-- <div class="echarts_box flex_shrink0">
            <h4 class="line_title">指数曲线</h4>
            <div class="line_box" @click="openLineEchartsDialog">
              <LineEcharts v-if="journalInfo.indexTrend"
                           :lineData="JSON.parse(journalInfo.indexTrend || '{}')" />
              <p class="empty_text" v-else>暂无</p>
            </div>
          </div> -->
        </div>
        <!-- 高搜弹框 -->
        <AdvanceSearchDialog ref="advancedSearch" :JournalName="journalInfo.fullName"
                             :showBox.sync="isOpenHeightSearch" dialogTitle="期刊搜索"
                             @advanceSearchChange="advanceSearchChange"
                             @resetAdvanceSearch="resetJournalDetails"
                             :beforeAdvanceSearch="beforeAdvanceSearch" />
        <!-- 搜索条件容器 -->
        <div class="advance_search_box" v-if="isHeightSearch && !showJournalInfo">
          <div class="search_keyword row_2">
            <i class="search_title">
              搜索条件:
            </i>
            <span v-for="(item,i) in advanceSearchOption" :key="item.title">
              <i class="fs14 requirement" v-if="i!==0">{{item.requirement}}</i>
              <span>[ {{item.title}} ] </span>
              <span>{{item.val}}</span>

            </span>
          </div>
          <div>

            <div v-show="isHeightSearch" class="display_flex">
              <span @click="onOpenHeightSearch" class="change_search_btn hand flc">修改</span>
              <span @click="onResetAdvanceSearch" class="hand reset_search_btn">重置</span>
            </div>
          </div>
        </div>
        <div class="options_container" v-show="!isHeightSearch && !showJournalInfo">
          <div class="info_btnBox">
            <div class="info-time">
              <!-- 年份下拉选择器 v-if="!isHeightSearch"-->
              <div class="nianfen">
                <span class="text">年刊</span>
                <el-select :disabled="isHeightSearch" popper-class="select" v-model="nianId"
                           placeholder="请选择年刊" @change="timeChange" size="mini">
                  <el-option v-for="item in timeList" :key="item.year" :label="item.year"
                             :value="item.year">
                    <span style="float: left">{{ item.year }}年</span>
                    <span
                          style="float: right; color: #8492a6; font-size: 13px">共{{ item.count }}篇</span>
                  </el-option>
                </el-select>
              </div>
              <!-- 月份下拉选择器 -->
              <div class="yuefen">
                <span class="text">月刊</span>
                <el-select :disabled="isHeightSearch" @change="timeChange" v-model="yueId" clearable
                           placeholder="请选择月刊" size="mini">
                  <el-option v-for="item in yueList" :key="item.id" :label="item.val"
                             :value="item.id">
                  </el-option>
                </el-select>
              </div>
              <!-- v-if="isUpdateList" -->
              <el-checkbox :disabled="isHeightSearch" @change="updateCheckChange"
                           class="update_checkout" v-model="isUpdateList" label="最新发表" border
                           size="mini"></el-checkbox>
            </div>
          </div>
          <div v-show="!isHeightSearch" @click="onOpenHeightSearch" class="height_search">期刊内搜索
          </div>
        </div>
        <!-- 文献列表盒子 -->
        <JournalList v-show="!showJournalInfo" :documentList="documentList" :isseeMore="isseeMore"
                     @seeMore="seeMore" />
        <!-- 期刊详情 -->
        <JournalInfo v-if="showJournalInfo" :journalInfo="journalInfo" />
        <!-- 指数曲线大图 -->
        <LineDialog :isLineDialog.sync="isLineDialog"
                    :lineData="JSON.parse(journalInfo.indexTrend || '{}')" />
      </div>
    </div>
  </div>
</template>
<script>
import { getJournalInfo, getDocumentListByJournal, getJournalDetails } from '@/api/classification.js'
import { JournalDocumentSum } from '@/utils/index.js'
import { gainHighSearch } from '@/api/article'
import AdvanceSearchDialog from '@/components/advanceSearchDialog/index.vue'
// import LineEcharts from './components/line.vue'
import LineDialog from './components/lineDialog.vue'
import JournalList from './components/journalList.vue'
import JournalInfo from './components/journalInfo.vue'
export default {
  components: {
    AdvanceSearchDialog,
    // LineEcharts,
    JournalInfo,
    LineDialog,
    JournalList
  },
  data () {
    return {
      isLineDialog: false, // 指数曲线弹窗状态位
      isOpenHeightSearch: false, //  高搜弹框
      isHeightSearch: false, // 是否是高级搜索
      journalId: '', // 期刊id
      journalInfo: {}, // 期刊信息
      timeList: [], // 期刊文献时间列表
      yueList: [], // 月份列表
      yueId: '', // 月份选中的id,
      nianId: '', // 年份选中id
      pageObj: {
        pageNum: 1,
        pageSize: 10
      },
      documentList: [], // 文献列表
      isseeMore: true, // 查看更多状态位
      journalKeywords: '', // 高搜条件
      advanceSearchOption: {}, // 搜索条件对象
      isUpdateList: false,
      showJournalInfo: false
    }
  },
  methods: {
    viewChange () {
      document.documentElement.scrollTop = 0
      this.showJournalInfo = !this.showJournalInfo
    },
    updateCheckChange (val) {
      if (val) {
        this.nianId = ''
      } else {
        this.nianId = this.timeList[0].year
      }
      this.yueId = ''

      this.documentList = []
      this.pageObj.pageNum = 1
      this.getDocumentList()
      document.documentElement.scrollTop = 0
    },
    async getJournalDetails () {
      const res = await getJournalDetails({
        journalId: this.journalId
      })
      res.data.tags = res.data.tags ? res.data.tags.slice(0, res.data.tags.length - 1).replace(/;/g, ' | ') : ''
      res.data.sum = res.data.yearsCount ? JournalDocumentSum(res.data.yearsCount) : 0
      this.journalInfo = res.data
    },
    // 查看大图
    openLineEchartsDialog () {
      if (!this.journalInfo.indexTrend) return
      this.isLineDialog = true
    },
    // 重置搜索
    onResetAdvanceSearch () {
      this.$refs.advancedSearch.reset()
    },
    // 搜索前校验
    beforeAdvanceSearch (AdvanceSearchConfig) {
      const advanceSearchVal = AdvanceSearchConfig.find(item => ((item.input || item.newTime) && item.name !== '期刊'))
      if (!advanceSearchVal) this.$message.warning('已在当前期刊下')
      return !!advanceSearchVal
    },
    // 展示搜索条件
    setKeywordsVal () {
      const optionKeyMap = {
        doc_title: '标题',
        doc_author: '作者',
        doc_abstract: '摘要',
        doc_source_journal: '期刊',
        doc_publish_time: '出版日期',
        doc_keywords: '关键字'
      }
      this.advanceSearchOption = this.journalKeywords.split('@@').filter(item => item).reduce((arr, item) => {
        const itemArr = item.split('$$')
        if (itemArr[1] !== 'doc_source_journal') {
          arr.push({
            requirement: itemArr[0],
            title: optionKeyMap[itemArr[1]],
            val: itemArr[1] === 'doc_publish_time' ? itemArr[2] + ' 至 ' + itemArr[3] : itemArr[2]
          })
        }
        return arr
      }, [])
    },
    advanceSearchChange (advanceSearchVal) {
      this.$myCount('PC-期刊内搜索')
      this.isHeightSearch = true
      this.isseeMore = true
      this.journalKeywords = advanceSearchVal
      this.setKeywordsVal()
      this.yueId = ''
      this.isUpdateList = false
      this.nianId = ''
      this.documentList = []
      this.pageObj.pageNum = 1
      document.documentElement.scrollTop = 0
      this.getDocumentList()
    },
    // 高搜弹框暴漏重置事件
    resetJournalDetails () {
      this.isHeightSearch = false
      this.advanceSearchOption = []
      this.pageObj.pageNum = 1
      this.nianId = this.timeList[0].year
      this.documentList = []
      this.isseeMore = true
      this.getDocumentList()
    },
    // 打开高搜弹框
    onOpenHeightSearch () {
      this.isOpenHeightSearch = true
      this.$refs.advancedSearch.setJournalName()
    },
    // 查看更多
    seeMore () {
      this.pageObj.pageNum++
      this.getDocumentList()
    },
    // 获取文献列表
    async getDocumentList () {
      let res = {}
      if (this.isHeightSearch) {
        // 高级搜索
        res = await gainHighSearch({
          keywords: this.journalKeywords,
          ...this.pageObj
        })
      } else {
        let date = ''
        if (this.isUpdateList) {
          date = this.journalInfo.updateCount.split(':')?.[0]
          this.nianId = ''
        } else {
          date = this.yueId ? this.nianId + '-' + this.yueId : this.nianId
        }
        res = await getDocumentListByJournal({
          ...this.pageObj,
          journalId: this.journalId,
          date
        })
      }
      if (res.data.length === 0 && this.documentList.length > 0) {
        this.isseeMore = false
        return this.$message.warning('暂无更多数据')
      }
      const data = res.data
      this.documentList = [...this.documentList, ...data]
    },
    // 下拉框改变触发事件
    timeChange () {
      this.isUpdateList = false
      this.isseeMore = true
      this.pageObj.pageNum = 1
      this.documentList = []
      this.getDocumentList()
      document.documentElement.scrollTop = 0
    },
    // 获取月份列表
    getYueList () {
      for (let i = 0; i < 12; i++) {
        this.yueList.push(
          { id: '0' + (i + 1), val: i + 1 + '月' }
        )
      }
    },
    // 获取期刊的时间列表
    async getJournalTimes () {
      try {
        const res = await getJournalInfo({ journalId: this.journalId })
        this.timeList = res.data
        this.nianId = this.timeList[0].year
        this.getDocumentList()
      } catch (error) {
        this.$message.error('获取时间信息失败')
      }
    }
  },
  created () {
    this.journalId = this.$route.query.journalId
    this.nianId = new Date().getFullYear()
    this.isUpdateList = this.$route.query.category === '1'
    this.getJournalDetails()
    this.getJournalTimes()
    this.getYueList()
  }
}
</script>
<style lang="less" scoped>
.JournalDetails-box {
  width: 100%;
  background-color: #f5f7fb;
  .JournalDetails-item {
    width: 1200px;
    margin: 0 auto;
    padding-top: 30px;
    // 期刊信息盒子
    .Journal-info {
      display: flex;
      align-items: center;
      position: -webkit-sticky;
      position: sticky;
      top: 52px;
      z-index: 100;
      width: 100%;
      height: 170px;
      padding: 20px;
      border-right: 10px;
      background-color: #fff;
      .el-image {
        width: 100px;
        height: 100%;
        margin-right: 20px;
      }
      .info-text {
        width: calc(100% - 300px);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .info-title {
          width: 100%;
          font-size: 16px;
          font-weight: 700;
        }
        .info-option {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          column-gap: 10px;
          span {
            width: 100%;
            font-size: 14px;
            color: #666;
          }
        }
        .tags_item {
          color: #666;
          font-size: 14px;
        }
      }
      .view_type {
        position: relative;
        width: 180px;
        height: 100%;
        .view_change_btn {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 105px;
          height: 30px;
          border: 1px solid #4b639f;
          color: #4b639f;
          border-radius: 2px;
          letter-spacing: 1px;
          font-weight: 600;
          .sort_icon {
            transform: rotate(90deg);
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
      .echarts_box {
        width: 200px;
        height: 100%;
        padding: 10px;
        border-radius: 2px;
        background-color: #f5f7fb;
        .line_title {
          font-weight: 600;
          margin-bottom: 5px;
        }
        .line_box {
          width: 100%;
          height: calc(100% - 22px);
        }
        .empty_text {
          width: 100%;
          height: 100%;
          line-height: 88px;
          font-size: 16px;
          color: #666;
          text-align: center;
          background-color: #fff;
        }
      }
    }
    // 搜索条件展示盒子
    .advance_search_box {
      display: grid;
      grid-template-columns: 1fr 100px;
      grid-gap: 0 20px;
      padding: 10px 0;
      .search_keyword {
        .search_title {
          font-size: 15px;
          font-weight: 600;
          color: #4b639f;
          margin-right: 5px;
        }
        .requirement {
          color: #fa6400;
          margin: 0 5px;
        }
      }
      .change_search_btn {
        width: 50px;
        height: 30px;
        background-color: #4b639f;
        color: #fff;
        font-size: 14px;
        border-radius: 2px;
      }
      .reset_search_btn {
        margin-left: 15px;
        height: 30px;
        line-height: 30px;
        color: #4b639f;
      }
    }
  }
}

.options_container {
  position: sticky;
  top: 222px;
  z-index: 99;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f5f7fb;
  .info_btnBox {
    // align-items: center;
    // 下拉框样式
    .info-time {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, auto);
      align-items: center;
      justify-content: start;
      column-gap: 30px;
      .el-select {
        width: 135px;
      }
      .text {
        margin-right: 10px;
        font-size: 16px;
      }
      /deep/ .update_checkout {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        .el-checkbox__input {
          height: 12px;
        }
        .el-checkbox__label {
          padding-left: 5px;
        }
      }
    }
  }
  .height_search {
    height: 28px;
    width: 100px;
    line-height: 28px;
    text-align: center;
    background-color: #4b639f;
    color: #fff;
    font-size: 13px;
    border-radius: 2px;
    cursor: pointer;
  }
}
</style>
