<template>
  <!-- 高级搜索弹框 -->
  <div v-show="showBox" class="advancedSearch">
    <!-- 内容盒子 -->
    <div class="advancedSearch-item animated fadeInUp">
      <div class="item-title">
        <h1>{{dialogTitle}}</h1>
        <i class="el-icon-circle-close hide_icon" @click="hideBox"></i>
      </div>

      <div class="item-middle">
        <div class="middle-title">
          <h3>关联符</h3>
          <h3>检索字段</h3>
          <h3>检索内容</h3>
        </div>
        <ul>
          <li class="animated fadeInUp" v-for="(item, index) in defaultOptions" :key="index">
            <!-- 选择器 -->
            <div class="select">
              <el-select placeholder="and" v-model="item.option">
                <el-option v-for="option in options" :key="option.value" :label="option.label"
                           :value="option.value" :disabled="JournalName && item.name ==='期刊'">
                </el-option>
              </el-select>
            </div>

            <!-- 标题 -->
            <span>{{item.name}}</span>
            <!-- 输入框 -->
            <div class="select_box" :class="{'is_delete':item.canDelete==='yes' }">
              <el-date-picker v-if="item.type === 'date'" v-model="item.time" type="monthrange"
                              format="yyyy年MM月" range-separator="至" start-placeholder="开始日期"
                              end-placeholder="结束日期" value-format="yyyy-MM" unlink-panels
                              @change="handleTime(item)" :picker-options="pickerOptions">
              </el-date-picker>
              <el-input v-else v-model="item.input" :readonly="JournalName && item.name==='期刊'"
                        :placeholder="`请输入检索${item.name}`">
              </el-input>
              <!-- 清空输入框的值 -->
              <i v-if="item.input !== '' && !(JournalName && item.name ==='期刊')"
                 class="el-icon-circle-close icon  hand" @click="clery(index)"></i>
              <!-- 删除当前项 -->
              <img @click="removeItem(item)" v-if="item.canDelete === 'yes'"
                   src="@/assets/img/docuInfo/highDel.png" alt="">
            </div>
          </li>
        </ul>
      </div>
      <!-- 底部按钮盒子 -->
      <div class="item-bom">
        <!-- 动态添加子段 -->
        <el-dropdown v-if="selectOptions.lenght >0" @command='addOption' class=" hand">
          <span class="el-dropdown-link">
            +字段
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="dropdown.id" v-for="dropdown in selectOptions"
                              :key="dropdown.id">{{dropdown.name}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="button  hand" @click="onSearch">检索</div>
        <div class="reset  hand" @click="reset">重置</div>
      </div>
    </div>
  </div>
</template>
<script>
import { gainHighField } from '@/api/article'
export default {
  name: 'advancedSearch',
  props: {
    // 弹框标题
    dialogTitle: {
      type: String,
      default: ''
    },
    // 弹框状态
    showBox: {
      type: Boolean,
      default: false
    },
    // 传入的期刊名
    JournalName: {
      type: String,
      default: ''
    },
    beforeAdvanceSearch: {
      type: Function,
      default: (AdvanceSearchConfig) => true
    }
  },
  data () {
    return {
      pickerOptions: {
        disabledDate: (timeval) => {
          return timeval > Date.now()
        },
        shortcuts: [
          {
            text: '去年',
            onClick (picker) {
              const year = (new Date().getFullYear() - 1).toString()
              const start = new Date(year)
              const end = new Date(year, 11)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '今年至今',
            onClick (picker) {
              const end = new Date()
              const start = new Date(new Date().getFullYear(), 0)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近六个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setMonth(start.getMonth() - 5)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      defaultOptions: [], // 默认的检索条件
      selectOptions: [], // 可以动态添加的检索条件
      options: [
        { value: 'AND', label: 'and' },
        { value: 'OR', label: 'or' },
        { value: 'NOT', label: 'not' }
      ]
    }
  },
  methods: {
    // 设置期刊名字
    setJournalName () {
      const journaItem = this.defaultOptions.find(item => item.name === '期刊')
      if (journaItem) {
        journaItem.input = this.JournalName
      }
    },
    // 回填原数据 停用
    setOptionsVal (key = 'keywords') {
      const data = localStorage.getItem(key)
      const optionsList = data.split('@@').filter(item => item).reduce((obj, item) => {
        const arr = item.split('$$')
        obj[arr[1]] = {
          option: arr[0],
          val: arr[1] === 'doc_publish_time' ? [arr[2], arr[3]] : arr[2]
        }
        return obj
      }, {})
      const options = this.selectOptions.filter(item => Object.keys(optionsList).includes(item.enName))
      this.defaultOptions = [...this.defaultOptions, ...options]
      this.defaultOptions.forEach(item => {
        if (optionsList[item.enName]) {
          if (item.enName === 'doc_publish_time') {
            item.time = optionsList[item.enName].val
            item.newTime = '$$' + optionsList[item.enName].val.join('$$')
          } else {
            item.input = optionsList[item.enName].val
          }
          item.option = optionsList[item.enName].option
        }
      })
    },
    // 获取高级搜索条件字段
    getAdvanceSearchServeVal (advanceSearchList) {
      return advanceSearchList.reduce((str, item) => {
        if (item.input || item.newTime) {
          if (item.type === 'date') {
            str += `@@${item.option}$$${item.enName}${item.newTime}`
          } else {
            str += `@@${item.option}$$${item.enName}$$${item.input}`
          }
        }
        return str
      }, '')
    },
    // 查询按钮
    onSearch () {
      if (!this.beforeAdvanceSearch(this.defaultOptions)) return
      const anvanceSearchStr = this.getAdvanceSearchServeVal(this.defaultOptions)
      this.$emit('advanceSearchChange', anvanceSearchStr)
      this.hideBox()
    },
    // 处理搜索类型数据
    setMapData (data) {
      return data.map(item => {
        return {
          enName: item.enName, // 搜索内容类别
          input: '', // 输入框内容
          name: item.name, // 标题
          type: item.type, // 类型
          canDelete: item.canDelete, // 是否可以删除
          time: [], // 时间选择器
          id: item.id, // 条件ID
          option: 'AND' // 搜索条件
        }
      })
    },
    // 获取后端返回的数据并处理成需要的格式
    async gainHighField (fn) {
      try {
        const res = await gainHighField()
        this.defaultOptions = this.setMapData(res.data.def)
        this.selectOptions = this.setMapData(res.data.notDef)
        fn && fn()
      } catch (error) {
        this.$message.error(error)
      }
    },
    // 重置所有内容
    reset () {
      this.$confirm('您确定要重置吗?', {
        title: '操作不可逆,请谨慎操作',
        appendToBody: true,
        center: true
      }).then(() => {
        this.defaultOptions = this.setMapData(this.defaultOptions)
        this.selectOptions = this.setMapData(this.selectOptions)
        this.setJournalName()
        this.$message.info('已重置')
        this.$emit('resetAdvanceSearch')
      }).catch((e) => {
      })
    },
    // 删除当前检索栏
    removeItem (item) {
      const i = this.defaultOptions.indexOf(item)
      this.defaultOptions.splice(i, 1)
      item.input = ''
      item.option = 'AND'
      if (item.enName === 'doc_publish_time') {
        item.newTime = ''
        item.time = []
      }
    },
    // 动态追加检索栏
    addOption (id) {
      let is = false
      this.defaultOptions.forEach(item => {
        if (item.id === id) is = true
      })
      if (is) return this.$message.warning('此项已存在不可重复添加')
      const arr = this.selectOptions.filter(item => item.id === id)
      this.defaultOptions.push(arr[0])
    },
    // 清空表格数据
    clery (i) {
      this.defaultOptions[i].input = ''
    },
    // 关闭弹框 删除本地缓存信息
    hideBox () {
      this.$emit('update:showBox', false)
    },
    // 排除空格并且判断内容不是纯空格
    exclude (val) {
      return val.replace(/(^\s*)|(\s*$)/g, '')
    },
    // 处理后端需要的时间格式
    handleTime (item) {
      if (item.time) {
        item.newTime = `$$${item.time[0]}-01$$${item.time[1]}-31` // '$$' + item.time.join('$$')
        console.log(item.newTime)
      } else {
        item.newTime = ''
        item.time = []
      }
    }
  },
  created () {
    this.gainHighField(() => {
      if (this.$route.query.val === 'senior') {
        this.setOptionsVal()
      }
    })
  }
}
</script>
<style lang="less">
.advancedSearch {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1010;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.29);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: #000;
  .el-input__inner {
    border-radius: 2px;
  }
  .advancedSearch-item {
    overflow-y: auto;
    width: 686px;
    z-index: 100;
    position: relative;
    background-color: #fff;
    border-radius: 2px;
    padding: 30px 50px 0;
    margin-top: 10vh;
    .item-title {
      position: relative;
      // height: 40px;
      margin-bottom: 21px;
      text-align: center;
      color: #4b639f;
      h1 {
        font-size: 18px;
        font-weight: bold;
      }
      .hide_icon {
        position: absolute;
        right: 0;
        top: 0;
        // top: 10px;
        // line-height: normal;
        font-size: 20px;
        transform: rotate(0deg);
        transition: transform 0.3s;
        &:hover {
          transform: rotate(90deg);
        }
      }
    }
    .item-middle {
      max-height: 65vh;
      overflow-y: auto;
      border-top: 1px solid #979797;
      border-bottom: 1px solid #979797;
      .middle-title {
        position: sticky;
        top: 0;
        z-index: 100;
        display: flex;
        height: 58px;
        background-color: #fff;
        h3 {
          line-height: 58px;
          font-size: 16px;
          text-align: center;
          font-weight: bold;
          color: #4b639f;
          &:first-child {
            flex: 2;
          }
          &:nth-child(2) {
            flex: 3;
          }
          &:last-child {
            flex: 5;
          }
        }
      }
    }
    ul {
      li {
        display: flex;
        margin-bottom: 20px;
        position: relative;
        .select {
          flex: 2;
          text-align: center;
          .el-select {
            width: 80px;
            border-radius: 4px;
          }
          .el-input__inner {
            font-size: 12px;
          }
          .el-input__icon {
            line-height: 24px;
          }
        }
        span {
          flex: 3;
          text-align: center;
          font-size: 14px;
          font-weight: bold;
          color: #000000;
        }
        .select_box {
          flex: 5;
        }
        .is_delete {
          display: flex;
        }
        .el-input__inner {
          height: 24px;
          font-size: 14px;
          color: #4b639f;
          border: 1px solid #ced2dd;
        }
        .icon {
          position: absolute;
          right: 6px;
          top: 6px;
          color: #909399;
        }
        img {
          position: relative;
          top: 4px;
          // right: -33px;
          width: 16px;
          height: 18px;
          margin-left: 5px;
        }
        .el-input__icon {
          line-height: 24px;
        }
        .el-date-editor {
          width: 100%;
          padding: 0 10px;
          .el-range-separator {
            padding: 0;
            font-size: 12px;
            line-height: 22px;
          }
        }
      }
    }

    // 下面按钮盒子
    .item-bom {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 78px;
      .el-dropdown {
        font-size: 16px;
        font-weight: bold;
        color: #4b639f;
      }
      .button {
        width: 300px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: #4b639f;
        border-radius: 2px;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
      }
      .reset {
        width: 138px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 2px;
        font-size: 14px;
        border: 2px solid #4b639f;
        font-weight: bold;
        color: #4b639f;
      }
    }
  }
}
</style>
