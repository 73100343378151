import { render, staticRenderFns } from "./journalInfo.vue?vue&type=template&id=0f0882b2&scoped=true&"
import script from "./journalInfo.vue?vue&type=script&lang=js&"
export * from "./journalInfo.vue?vue&type=script&lang=js&"
import style0 from "./journalInfo.vue?vue&type=style&index=0&id=0f0882b2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f0882b2",
  null
  
)

export default component.exports