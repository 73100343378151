<template>
  <div class="journal_info_container">
    <div class="info_card mb15">
      <div class="info_title">
        <div class="card_title">基本信息</div>
        <div class="card_title">投稿信息</div>
      </div>
      <div class="card_content">
        <div class="info_item" v-for="map in infoKeyMpas" :key="map.key">
          <span>{{map.label}}：</span>
          <span v-if="map.key">{{journalInfo[map.key] || '--'}}</span>
          <div v-if="!map.key" class="links">
            <a v-if="journalInfo.homePage" :href="journalInfo.homePage" target="_blank">期刊主页</a>
            <a v-if="journalInfo.links" :href="journalInfo.links" target="_blank">投稿网址</a>
          </div>
        </div>
      </div>
    </div>
    <div class="info_card mb15">
      <div class="card_title">中科院分区2023升级版（试行）</div>
      <FqTable :list="journalInfo.zkyAreas" :tableMaps="zkyTableMaps" isMerge />
    </div>
    <div class="info_card mb15">
      <div class="card_title">WOS期刊分区</div>
      <FqTable :list="journalInfo.zkyAreas" :tableMaps="wosTableMaps" />
    </div>
    <div class="info_card mb15" v-if="journalInfo.indexTrend">
      <div class="card_title">影响因子</div>
      <div class="lin_chart_container">
        <LineEcharts :lineData="JSON.parse(journalInfo.indexTrend || '{}')" domID="lineDialog"
                     :grid="grid" :isShowAxis="true" :width="1" :showSymbol="true" />
      </div>
    </div>
  </div>
</template>

<script>
import FqTable from './fqTable.vue'
import LineEcharts from './line.vue'
export default {
  props: {
    journalInfo: {
      type: Object,
      default: () => ({})
    }
  },
  components: { LineEcharts, FqTable },
  data () {
    return {
      grid: {
        top: '8%',
        left: '5%',
        right: '3%',
        bottom: '10%'
      },
      infoKeyMpas: [
        {
          key: 'shortName',
          label: '期刊简介'
        }, {
          key: 'startYear',
          label: '起止年'
        }, {
          key: 'auditCycle',
          label: '审稿周期'
        }, {
          key: 'frequency',
          label: '出版周期'
        }, {
          key: 'language',
          label: '语种'
        }, {
          key: 'hitRate',
          label: '投稿命中率'
        }, {
          key: 'publisher',
          label: '出版商'
        }, {
          key: 'country',
          label: '出版国'
        }, {
          key: '',
          label: '更多信息'
        }
      ],
      zkyTableMaps: [
        {
          prop: 'salMajorDiscipline',
          label: '大类学科'
        }, {
          prop: 'salMajorQuyu',
          label: '分区'
        }, {
          prop: 'salSmallCn',
          label: '小类学科'
        }, {
          prop: 'salSmallSelection',
          label: '分区'
        }
      ],
      wosTableMaps: [
        {
          prop: 'subjectd',
          label: '学科分区类别'
        }, {
          prop: 'quartile',
          label: '分区'
        }
      ]
    }
  },
  methods: {

  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.journal_info_container {
  min-height: calc(100vh - 200px);
  margin-top: 20px;
  padding-bottom: 1px;
}
.info_card {
  padding: 20px;
  background-color: #fff;
  border-radius: 2px;
}
.card_content {
  display: grid;
  grid-template-columns: repeat(3, 33.333%);
  align-items: center;
  justify-content: space-between;
  row-gap: 8px;
  color: #666;
  font-size: 14px;
  .info_item {
    display: flex;
  }
  .links {
    display: flex;
    align-items: center;
    a {
      position: relative;
      color: #4b639f;
      font-weight: 600;
      margin-right: 20px;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: -10px;
        width: 2px;
        height: 10px;
        transform: translate(0, -50%);
        background-color: #4b639f;
      }
      &:last-child {
        margin-right: 0;
        &::after {
          background-color: transparent;
          right: 0;
        }
      }
    }
  }
}
.info_title {
  display: grid;
  grid-template-columns: 66.666% 33.333%;
  align-items: center;
  justify-content: space-between;
}
.card_title {
  font-weight: 600;
  font-size: 18px;
  color: #4b639f;
  margin-bottom: 8px;
}

.lin_chart_container {
  width: 100%;
  height: 350px;
}
</style>
